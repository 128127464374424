var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"police-box"},[_c('vxe-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.alarmsData,"align":"center","border":"","stripe":"","height":_vm.tabHeight,"default-expand-all":"","empty-text":_vm.showLang('com.data.no')}},[_c('vxe-column',{attrs:{"type":"seq","width":"55","title":_vm.showLang('com.export.cn.seq'),"fixed":"left"}}),_c('vxe-column',{attrs:{"field":"stationName","title":"站点","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"stationName",attrs:{"title":row.stationName},on:{"click":function($event){return _vm.jumpDetailsDve(row)}}},[_vm._v(" "+_vm._s(row.stationName))])]}}])}),_c('vxe-column',{attrs:{"field":"paramName","title":"设备","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{on:{"click":function($event){return _vm.showAlarmHisData(row)}}},[_vm._v(" "+_vm._s(row.paramName))])]}}])}),_c('vxe-column',{attrs:{"field":"lightCount","title":"类型","sortable":"","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"red",on:{"click":function($event){return _vm.showAlarmHisData(row)}}},[_vm._v(" "+_vm._s(row.name))])]}}])}),_c('vxe-column',{attrs:{"field":"offlineError","title":"内容","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{style:({color: row.isConfirm ? 'blue' : 'red'}),on:{"click":function($event){return _vm.showAlarmHisData(row)}}},[_vm._v("["+_vm._s(row.isConfirm ? _vm.showLang('com.order.confirmed') : _vm.showLang('com.state.unconfirmed'))+"]")]),_c('span',{staticClass:"red",on:{"click":function($event){return _vm.showAlarmHisData(row)}}},[_vm._v(" "+_vm._s(row.extra))])]}}])}),_c('vxe-column',{attrs:{"field":"startTime","title":"时间","sortable":"","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{on:{"click":function($event){return _vm.showAlarmHisData(row)}}},[_vm._v(" "+_vm._s(row.startTime))])]}}])})],1),_c('ModalAlarmData',{attrs:{"item":_vm.editItem},model:{value:(_vm.showAlarmDataModal),callback:function ($$v) {_vm.showAlarmDataModal=$$v},expression:"showAlarmDataModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }