var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"table1",staticClass:"power-box"},[_c('vxe-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.alarmsData,"align":"center","border":"","stripe":"","height":_vm.tabHeight,"default-expand-all":"","empty-text":_vm.showLang('com.data.no')}},[_c('vxe-column',{attrs:{"type":"seq","width":"55","title":_vm.showLang('com.export.cn.seq'),"fixed":"left"}}),_c('vxe-column',{attrs:{"field":"stationName","title":"站点"}}),_c('vxe-column',{attrs:{"field":"createTime","title":"分析时间"}}),_c('vxe-column',{attrs:{"field":"lightCount","title":"单灯总数","sortable":""}}),_c('vxe-column',{attrs:{"field":"offlineCount","title":"离线数","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:[row.offlineCount>0?'red':''],on:{"click":function($event){return _vm.showDetails(2,row,row.offlineCount)}}},[_vm._v(" "+_vm._s(row.offlineCount))])]}}])}),_c('vxe-column',{attrs:{"field":"offlineError","title":"离线异常数","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:[row.offlineError>0?'red':''],on:{"click":function($event){return _vm.showDetails(0,row,row.offlineError)}}},[_vm._v(" "+_vm._s(row.offlineError))])]}}])}),_c('vxe-column',{attrs:{"field":"powerOffCount","title":"灭灯数","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:[row.powerOffCount>0?'red':''],on:{"click":function($event){return _vm.showDetails(3,row,row.powerOffCount)}}},[_vm._v(" "+_vm._s(row.powerOffCount))])]}}])}),_c('vxe-column',{attrs:{"field":"poweroffError","title":"灭灯异常数","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:[row.poweroffError>0?'red':''],on:{"click":function($event){return _vm.showDetails(1,row,row.poweroffError)}}},[_vm._v(" "+_vm._s(row.poweroffError))])]}}])}),_c('vxe-column',{attrs:{"field":"voltageUpperCount","title":"过压数","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:[row.voltageUpperCount>0?'red':''],on:{"click":function($event){return _vm.showDetails(4,row,row.voltageUpperCount)}}},[_vm._v(" "+_vm._s(row.voltageUpperCount))])]}}])}),_c('vxe-column',{attrs:{"field":"voltageLowerCount","title":"欠压数","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:[row.voltageLowerCount>0?'red':''],on:{"click":function($event){return _vm.showDetails(5,row,row.voltageLowerCount)}}},[_vm._v(" "+_vm._s(row.voltageLowerCount))])]}}])})],1),_c('ModelPowerLineError',{attrs:{"item":_vm.item,"type":_vm.showTypeUrl},model:{value:(_vm.isPowerLineError),callback:function ($$v) {_vm.isPowerLineError=$$v},expression:"isPowerLineError"}}),_c('ModelLightPowerError',{attrs:{"item":_vm.item,"type":_vm.showType},model:{value:(_vm.isLightPowerError),callback:function ($$v) {_vm.isLightPowerError=$$v},expression:"isLightPowerError"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }